<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>Academics</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                 <table >
                      <tr>
                        <th>Class</th>
                        <th>Male</th>
                        <th>Female</th>
                        <th>Total</th>
                        <th>Class Teacher</th>
                        <th>Contact No</th>
                      </tr>
                      <tr v-for="cs in ClassStat" :key="cs.id">
                        <td>{{cs.Class}}</td>
                        <td>{{cs.Male}}</td>
                        <td>{{cs.Female}}</td>
                        <td>{{cs.Total}}</td>
                        <td>{{cs.ClassTeacher}}</td>
                      </tr>

                    </table>
                 
   
   
              </div>
              
          <div class="sidebar">
              <AcademicsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import AcademicsQuickLinks from "../../components/page/AcademicsQuickLinks.vue";

import ClassStats from '@/assets/data/ClassStats.json'
import Footer from "../../components/page/Footer.vue";



export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, AcademicsQuickLinks, Footer },
    setup(){
        const title= ref('Class Teachers')
        return {title}
    },
    data(){
        return {
            ClassStat: ClassStats,
        }
    }
  
}
</script>

<style scoped>
table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  
}
table, th,td{
  border: 1px solid rgb(146, 138, 138);
  text-align: center;
  padding: .2rem
}
table th{
  background: var(--secondary);
  color: var(--pure);
  font-weight: 200;
}
</style>